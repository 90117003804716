*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	font-size: 16px;
	--bg: #090e17;
	--primary: #40e0d0;
	--gradientBg: linear-gradient(45deg, #40e0d0, #9932cc, #ffa500);
}

::selection {
	background-color: var(--primary);
	color: var(--bg);
}

body {
	margin: 0;
	--color-text: #fff;
	--color-bg: #000;
	--color-link: #fff;
	--color-link-hover: rgb(58, 49, 49);
	color: var(--color-text);
	background-color: var(--color-bg);
	cursor: none;
	--cursor-stroke: none;
  --cursor-fill: var(--bg);
  --cursor-stroke-width: 1px;
	font-family: "Nunito", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* Page Loader */
/* Page Loader */
.js body::before,
.js body::after {
	content: "";
	position: fixed;
	z-index: 1000;
	transition: opacity 0.3s;
	opacity: 0;
	pointer-events: none;
}

.js body::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--bg);
}

.js body::after {
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: url("../img/loader.gif") no-repeat center;
}

.js .loading::before,
.js .loading::after {
	opacity: 1;
	pointer-events: auto;
}

a {
	text-decoration: none;
	color: var(--color-link);
	outline: none;
}

a:hover {
	color: var(--color-link-hover);
	outline: none;
}

a:focus {
	/* Provide a fallback style for browsers
 that don't support :focus-visible */
	outline: none;
	background: lightgrey;
}

a:focus:not(:focus-visible) {
	/* Remove the focus indicator on mouse-focus for browsers
 that do support :focus-visible */
	background: transparent;
}

a:focus-visible {
	/* Draw a very noticeable focus style for
 keyboard-focus on browsers that do support
 :focus-visible */
	outline: 2px solid red;
	background: transparent;
}

.link {
	cursor: none;
	position: relative;
	white-space: nowrap;
	color: var(--color-text);
}

.link::before,
.link::after {
	position: absolute;
	width: 100%;
	height: 1px;
	background: currentColor;
	top: 100%;
	left: 0;
	pointer-events: none;
}

.link::before {
	content: "";
	/* show by default */
}

.link--dia {
	font-size: clamp(1.5rem, 2vw, 2.5rem);
	outline: none;
}

.link--dia::before,
.link--dia::after {
	opacity: 0;
	transform-origin: 50% 0%;
	transform: translate3d(0, 3px, 0);
	transition-property: transform, opacity;
	transition-duration: 0.3s;
	transition-timing-function: cubic-bezier(0.2, 1, 0.8, 1);
}

.link--dia:hover::before,
.link--dia:hover::after {
	opacity: 1;
	transform: translate3d(0, 0, 0);
	transition-timing-function: cubic-bezier(0.2, 0, 0.3, 1);
}

.link--dia::after {
	content: "";
	top: calc(100% + 4px);
	width: 70%;
	left: 15%;
}

.link--dia::before,
.link--dia:hover::after {
	transition-delay: 0.1s;
}

.link--dia:hover::before {
	transition-delay: 0s;
}

.content {
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: calc(100vh - 13rem);
	position: relative;
	justify-content: flex-start;
	align-items: center;
	font-family: "Montserrat", sans-serif;
	min-height: 100vh;
	margin: 0;
	padding: 0;
	background-color: var(--bg);
	color: #ffffff;
}

.content ul {
	padding: 0;
	list-style: none;
}

.content li {
	padding: 1.5rem;
	text-transform: uppercase;
}

.content button {
	border: none;
	padding: 0.5rem;
	font-size: inherit;
	cursor: none;
}

.wrapper {
	position: relative;
	width: 100%;
}

.container {
	width: 100%;
	max-width: 84rem;
	margin: 0 auto;
}

.hero__heading {
	font-size: clamp(2rem, 9vw, 8rem);
	line-height: 1.1;
	font-weight: 900;
	margin: 0;
}

.hero__heading .country {
	font-size: clamp(2rem, 5vw, 3.5rem);
	letter-spacing: 0.15rem;
}

.hero--primary .hero__heading {
	font-family: Arial, Helvetica, sans-serif;
	-webkit-text-stroke: 2px var(--primary);
	color: transparent;
	filter: drop-shadow(0 0 0.2rem var(--primary));
}

.hero__hobby {
	font-family: Arial, Helvetica, sans-serif;
	-webkit-text-stroke: 2px #ff9f10;
	color: transparent;
	filter: drop-shadow(0 0 0.2rem var(--primary));
}

.hero--secondary .hero__heading {
	background: radial-gradient(circle at center, #ffffff 0.1rem, transparent 0);
	background-size: 0.4rem 0.4rem;
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
}

.menu {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: fixed;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	background: var(--gradientBg);
	clip-path: circle(0 at calc(100% - 2rem) 2rem);
	transition: clip-path 1s;
	z-index: 1;
	font-size: clamp(1rem, 3vw, 4rem);
}

.menu.is-open {
	clip-path: circle(200% at calc(100% - 2rem) 2rem);
	pointer-events: all;
}

.menu-button {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--color-bg);
	color: var(--bg);
	padding: 0.75rem;
	width: 3.5rem;
	height: 3.5rem;
	top: 1rem;
	right: 1rem;
	z-index: 2;
	border-radius: 50%;
}

.menu-button__icon {
	display: block;
	position: relative;
	overflow: visible;
}

.menu-button__icon span,
.menu-button__icon::before,
.menu-button__icon::after {
	display: block;
	width: 2rem;
	height: 4px;
	background-color: white;
}

.menu-button__icon::before,
.menu-button__icon::after {
	content: "";
	position: absolute;
	left: 0;
	top: -8px;
	transition: transform 200ms;
	transform-origin: center;
	pointer-events: none;
}

.menu-button__icon::after {
	bottom: -8px;
	top: auto;
}

.menu-button.is-active .menu-button__icon > span {
	opacity: 0;
}

.menu-button.is-active .menu-button__icon::before {
	transform: translate3d(0, 8px, 0) rotate(45deg);
}

.menu-button.is-active .menu-button__icon::after {
	transform: translate3d(0, -8px, 0) rotate(-45deg);
}

.hero {
	--x: 50%;
	--y: 50%;
	min-height: 100vh;
	padding: clamp(1rem, 2vw, 5rem);
	display: flex;
	align-items: center;
}

.hero--secondary {
	--mask: radial-gradient(
		circle at var(--x, 50%) var(--y, 50%),
		black var(--maskSize1, 0%),
		rgba(0, 0, 0, 0.1) calc(var(--maskSize1, 0%) + 0.1%),
		transparent 0,
		transparent var(--maskSize2, 0%),
		rgba(0, 0, 0, 0.1) calc(var(--maskSize2, 0%) + 0.1%),
		black var(--maskSize2, 0%),
		rgba(0, 0, 0, 0.1) calc(var(--maskSize3, 0%) - 0.1%),
		black var(--maskSize3, 0%),
		rgba(0, 0, 0, 0.1) calc(var(--maskSize3, 0%) + 0.1%),
		transparent 0
	);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	background: var(--gradientBg);
	color: rgb(9, 14, 23);
	-webkit-mask-image: var(--mask);
	mask-image: var(--mask);
}

.hero--primary .hero__heading .char {
	display: inline-block;
}

.cursor {
	display: none;
}

@media (any-pointer: fine) {
	.cursor {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		pointer-events: none;
		mix-blend-mode: color-burn;
		z-index: 100;
	}
	.cursor__inner {
		fill: var(--cursor-fill);
		stroke: var(--cursor-stroke);
		stroke-width: var(--cursor-stroke-width);
		opacity: 0.7;
		z-index: 100;
	}
	.no-js .cursor {
		display: none;
	}
}

@media screen and (min-width: 53em) {
	.content {
		height: 100vh;
		justify-content: center;
	}
}

@media screen and (max-width: 53em) {
	.hero__heading {
		letter-spacing: 2px;
	}

	.hero--secondary .hero__heading {
		color: #fff;
	}
}
